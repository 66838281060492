import React, { createRef } from "react";

export class OutsideClickHandler extends React.Component {
    wrapperRef = createRef();

    static defaultProps = {
        onOutsideClick: () => {}
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener('keydown', (event) =>{
                if (event.key === "Escape") {
                    return this.handleClickOutside(event)
                }
            });
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.addEventListener('keydown', (event) =>{
            if (event.key === "Escape") {
                    return this.handleClickOutside(event)
                }
            });
    }

    handleClickOutside = (event) => {
        if (
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.props.onOutsideClick();
        }
    };

    render() {
        const { children } = this.props;

        return <div ref={this.wrapperRef}>{children}</div>;
    }
}