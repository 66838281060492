import React from 'react';
import '../../App.css';
import {ContactCards} from "../ContactUs-Components/Contact-Cards";

export default function ContactUs() {
  return(
      <>
        <ContactCards/>
    </>
  )
}