import React from 'react';
import '../../App.css';
import SubmitFormLayout from "../ContactUs-Components/SubmitFormLayout";



function SubmitForm() {

    return (

        <>
            <SubmitFormLayout></SubmitFormLayout>
        </>
    );
}

export default SubmitForm;