export const listings = [
    {
        src1:'listingPhotos/Mawgan_Porth/first.jpg',
        src2:'listingPhotos/Mawgan_Porth/second.jpg',
        src3:'listingPhotos/Mawgan_Porth/third.jpg',
        src4:'listingPhotos/Mawgan_Porth/fourth.jpg',
        src5:'listingPhotos/Mawgan_Porth/fifth.jpg',
        src6:'listingPhotos/Mawgan_Porth/sixth.jpg',
        src7:'listingPhotos/Mawgan_Porth/seventh.jpg',
        src8:'listingPhotos/Mawgan_Porth/eighth.jpg',
        id:'0001',
        title:'The Dunes - a beautiful beach side cottage',
        location:'Mawgan Porth, Cornwall, England',
        price:'650',
        label:'Luxury',
        details:['-  6 Guests','-  3 Bedrooms','-  2 Bathrooms','-  Beach Access','-  Pets Allowed','-  Kitchen','-  WIFI','-  Free Parking'],
        description:['Beautiful bright and spacious beach house offering comfortable beds, wood fired hot tub, meditation platform with sea views and a yoga studio. The Dunes is nestled in the sand dunes of Mawgan Porth on the Cornish Coast and has direct access onto the beach. This stay boasts stylish interiors, a good selection of books and DVDS, fast wifi, lovely coastal walks and more.'],
        extras:'Minimum stay of 4 nights',
    },
    {
        src1:'listingPhotos/Alisons_Barn/first.jpg',
        src2:'listingPhotos/Alisons_Barn/second.jpg',
        src3:'listingPhotos/Alisons_Barn/third.jpg',
        src4:'listingPhotos/Alisons_Barn/fourth.jpg',
        src5:'listingPhotos/Alisons_Barn/fifth.jpg',
        src6:'listingPhotos/Alisons_Barn/sixth.jpg',
        src7:'listingPhotos/Alisons_Barn/seventh.jpg',
        src8:'listingPhotos/Alisons_Barn/eighth.jpg',
        id:'0002',
        title:'Stunning countryside cottage',
        location:'Chulmleigh, Devon, England',
        price:'275',
        label:'Luxury',
        details:['-  10 Guests','-  4 Bedrooms','-  6 Beds','-  2.5 Bathrooms','-  Private hot tub','-  Garden','-  Pets Allowed','-  Kitchen','-  WIFI','-  Free Parking'],
        description:['Spacious \'upside down\' house with open plan living and dining area. Lovely countryside views and a well-equipped kitchen which opens on to a sunny, west facing terrace overlooking private enclosed garden - great for evening drinks and BBQs with stunning sunsets and star-studded evening skies to enjoy. Downstairs sleeping area (sleeps 6) has 1 spacious king-size room with ensuite access to shared bathroom; 1 twin (can be made as double); 2 small singles or 1 small double. Additional space in Garden room suite (sleeps 3), super king size + extra single bed (if required). 6+ booking will include this Garden Suite.'],
        extras:'Minimum stay of 2 nights',
    },
    {
        src1:'listingPhotos/Amberstone_Manor/first.jpg',
        src2:'listingPhotos/Amberstone_Manor/second.jpg',
        src3:'listingPhotos/Amberstone_Manor/third.jpg',
        src4:'listingPhotos/Amberstone_Manor/fourth.jpg',
        src5:'listingPhotos/Amberstone_Manor/fifth.jpg',
        src6:'listingPhotos/Amberstone_Manor/sixth.jpg',
        src7:'listingPhotos/Amberstone_Manor/seventh.jpg',
        src8:'listingPhotos/Amberstone_Manor/eighth.jpg',
        src9:'listingPhotos/Amberstone_Manor/ninth.jpg',
        src10:'listingPhotos/Amberstone_Manor/tenth.jpg',
        src11:'listingPhotos/Amberstone_Manor/eleventh.jpg',
        src12:'listingPhotos/Amberstone_Manor/twelfth.jpg',
        src13:'listingPhotos/Amberstone_Manor/thirteenth.jpg',
        id:'0003',
        title:'Amberstone Manor - Magnificent 19th century country house',
        location:'Chulmleigh, Devon, England',
        price:'1700',
        label:'Luxury',
        details:['-  18 Guests','-  9 Bedrooms','-  9 Bathrooms','-  Tennis court','-  Swimming pool','-  Yoga studio','-  Games room + snooker table','-  Sauna room','-  Pets allowed','-  Free Parking'],
        extraServices:['-  Chef Available','-  Maid Service Available','-  Babysitting Available','-  Yoga instructor available with advanced booking'],
        description:['This is a 3 storey 19th Century country house situated in the unspoiled Devon countryside and nestled between Exmoor and Dartmoor. Amberstone Manor is set in 5 acres of landscaped gardens and 20 acres of river meadows. The property has been totally refurbished and offers nine beautifully-appointed bedrooms all with en-suite bathrooms. It sleeps up to eighteen adults and is able to provide additional accommodation for children or staff on request. The ground floor has two very large reception and dining areas and a lounging TV room to relax in. There is also a large farmhouse style kitchen with ancillary rooms and the dining room that seats up to eighteen. This stay boasts a private swimming pool set amongst tropical plants, an artificial grass tennis court, an infrared sauna room, a games room with a snooker table and a yoga studio. A lawn for croquet, an orchard for apples, a vegetable garden for fresh produce all add up to the quintessential English country garden.'],
        extras:'Minimum stay of 6 nights. In July and August stays are limited to 6 days only. Meet and greet service: the owner will provide a tour and show you how all works.'
    }
]