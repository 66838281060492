import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {Link} from "react-router-dom";
import {Button1} from "./Button";
import {OutsideClickHandler} from "./outsideClick"
import {GuestsDropdown} from "./React-Select"


export class Example extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = this.getInitialState();

    }

    getInitialState() {
        return {
            from: null,
            to: null,
            enteredTo: null, // Keep track of the last day for mouseEnter.

        };
    }

    isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    handleDayClick(day) {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.handleResetClick();
            return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
        } else {
            this.setState({
                to: day,
                enteredTo: day,
            });
        }
    }

    handleDayMouseEnter(day) {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        }
    }

    handleResetClick() {
        this.setState(this.getInitialState());
        document.getElementById('from-input').value = ""
        document.getElementById('until-input').value = ""
    }
    state = { showing: true };

    render() {

        const { showing } = this.state;

        const { from, to, enteredTo } = this.state;
        const modifiers = { start: from, end: enteredTo };

        // const disabledDays = {before: this.state.from};
        // const disabledDays = {before: new Date(2021, 7, 10)};
        const date = new Date()
        const disabledDays = {before: new Date(date.getFullYear(), date.getMonth(), date.getDate())};

        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }

        const selectedDays = [from, { from, to: enteredTo }];

        return (


            <div>

                <form className='searchContainer'>

                    <div className="searchinner">
                        <label className='searchBox-labels Where-To-Input' >Where To?</label><br/>
                        <input type="text" className="searchBox-inputs Where-To-Input" placeholder="Location" />
                    </div>

                    <div className="searchinner noDisplay">
                        <label className='searchBox-labels' >Check In</label><br/>
                        <input id='from-input' className="searchBox-inputs" placeholder='From' readOnly={true} style={{cursor:'pointer'}} value= {from ? `${from.toLocaleDateString(undefined, options)}` : ""} onClick={() => this.setState({ showing: !showing })}/>
                    </div>

                    <div className="searchinner noDisplay" >
                        <label className='searchBox-labels' >Check Out</label><br/>
                        <input id='until-input' className="searchBox-inputs" placeholder="Until" readOnly={true} style={{cursor:'pointer'}} value={from && to ? `${to.toLocaleDateString(undefined, options)}` : ""} onClick={() => this.setState({ showing: !showing })} />
                        {from && to && (
                            <i className="far fa-times-circle link" onClick={this.handleResetClick}/>
                        )}
                    </div>

                    { showing ?
                         <div className='toggletoggletoggle'  >

                             <OutsideClickHandler
                                 onOutsideClick={() => {
                                     this.setState({ showing: !showing });
                                 }}
                             >
                                 <DayPicker
                                     className="Range"
                                     numberOfMonths={2}
                                     fromMonth={from}
                                     selectedDays={selectedDays}
                                     disabledDays={disabledDays}
                                     modifiers={modifiers}
                                     onDayClick={this.handleDayClick}
                                     onDayMouseEnter={this.handleDayMouseEnter}
                                 />

                             </OutsideClickHandler>

                        </div>
                        : null
                    }

                    <div className="searchinner noDisplay">
                        <label className='searchBox-labels' >Guests</label><br/>

                        <GuestsDropdown/>

                    </div>

                    <div className='Search-Btn'>
                        <Link to='/top-picks' className='btn-mobile' ><Button1 buttonStyle='search-btn--outline' buttonSize='search-btn--medium'>Search</Button1></Link>
                    </div>

                </form>

                <Helmet>
                    <style>{`
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Range .DayPicker-Day {
    border-radius: 0 !important;
  }
`}</style>
                </Helmet>
            </div>

        );
    }
}