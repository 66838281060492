import React from 'react';
import '../../../App.css';
import {Link} from 'react-router-dom'
import HostingForm from "../../Hosting-Components/HostingForm";

export default function BecomeHost(){
    return (
        <>

            <div className='cards'>

                <h1 className='ContactUs'>Interested in hosting your stay with CloudJoy?</h1>
                <br/>
                <h3>Please fill out the form below and we'll do the rest!</h3>
                <br/>
                <p> A member of our team will be in touch shortly to inform you that your listing has been posted and it will automatically appear in your <Link to='/account'>account</Link>.
                </p>
            </div>

            <HostingForm/>

        </>
    )
}

