import React  from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/Homepage-Components/Navbar';
import Footer from "./components/Homepage-Components/Footer";
import './App.css';
import Home from './components/pages/Home';
import TopPicks from "./components/pages/Booking/TopPicks";
import IndividualListingPage from "./components/pages/Booking/IndividualListingPage";
import BookingLearnMore from "./components/pages/Booking/BookingLearnMore";
import BecomeHost from "./components/pages/Hosting/BecomeHost";
import HostingLearnMore from "./components/pages/Hosting/HostingLearnMore";
import ContactUs from './components/pages/ContactUs';
import SubmitForm from "./components/pages/SubmitForm";
import Auth from "./components/Auth/Auth";
import Account from "./components/pages/Account"

import {useDispatch} from "react-redux";
import {CLEAR_ERROR} from "./constants/actionTypes";
import AuthGuard from "./components/HOCs/authGuard";

function App() {

  const dispatch = useDispatch();
  const clearError = () => (dispatch({ type: CLEAR_ERROR, error: null }));

  return (

  <div      style={{
    backgroundColor: 'rgb(248,248,248)',
  }}>

<Router>
  <Navbar/>
  <Switch>
  <Route path='/' exact component={ () => clearError() && <Home/>} />
  <Route path='/top-picks' exact component={ () => clearError() && <TopPicks/>} />
  <Route path='/booking/learn-more' exact component={ () => clearError() && <BookingLearnMore/>} />
  <Route path='/become-a-host' exact component={ () => clearError() && <BecomeHost/>} />
  <Route path='/stays/:id' exact component={ () => clearError() && <IndividualListingPage/>} />
  <Route path='/hosting/learn-more' exact component={ () => clearError() && <HostingLearnMore/>} />
  <Route path='/contact-us' exact component={ () => clearError() && <ContactUs/>} />
  <Route path='/submit-a-form' exact component={ () => clearError() && <SubmitForm/>} />
  <Route path='/auth' exact component={ () => AuthGuard() ? <Redirect to='/'/> : <Auth/>} />
  <Route path='/account' exact component={ ()  => AuthGuard() ? <Account/> : <Auth/>} />
  <Redirect from="*" to='/' />
  </Switch>
  <Footer />
</Router>
</div>

  );
}

export default App;

