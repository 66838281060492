import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (

      <div className='cards'>
          <h1>Hand selected stays</h1>
          <div className='cards__container'>
              <div className="cards_row">
                  <div className="cards_column">

                      <CardItem
                          src='images/homepage3.jpg'
                          text='London'
                          label='Adrenaline'
                          path='/'
                      />
                  </div>

                  <div className="cards_column">
                      <CardItem
                          src='images/homepage1.jpg'
                          text='Norway'
                          label='Adrenaline'
                          path='/'
                      />
                  </div>

                  <div className="cards_column">
                      <CardItem
                          src='images/homepage4.jpg'
                          text='France'
                          label='Adrenaline'
                          path='/'

                      />
                  </div>

                  <div className="cards_column">
                      <CardItem
                          src='images/homepage5.jpg'
                          text='Ireland'
                          label='Adrenaline'
                          path='/'
                      />
                  </div>
              </div>
          </div>
      </div>
  );
}
export default Cards;


