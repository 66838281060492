import React, {useEffect, useState} from "react";
import {Avatar, Button, Paper, Grid, Typography, Container} from "@material-ui/core";
import { GoogleLogin } from 'react-google-login';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory } from 'react-router-dom'

import Icon from "./icon";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from './Styles';
import Input from './Input';
import { signin, signup } from '../../actions/auth';
import { AUTH, CLEAR_ERROR } from "../../constants/actionTypes";

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: ''}


const Auth = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [msg, setMsg] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector(state => state.errors.error);
    const history = useHistory();

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const handleSubmit = async (e) =>{
        e.preventDefault();

        if (isSignup) {
            dispatch(signup(formData,history))
        } else {
             dispatch(signin(formData, history))
        }
    };

    useEffect(() => {
        setMsg(selector)
    },[selector])

    const handleChange = (e) =>{
        setFormData({ ...formData, [e.target.name]: e.target.value})
    };

    const switchMode = () => {
        dispatch({ type: CLEAR_ERROR, error: null });
        setIsSignup((prevIsSignup) => !prevIsSignup);
        setShowPassword(false);

    }

    const googleSuccess = async (res) => {
        const result = res?.profileObj;
        const token = res?.tokenId;

        try{
            dispatch({ type: AUTH, data: { result, token } });
            history.push('/');
            // res.status(200).cookie("access_token", token, { httpOnly: true }).json({ success:true });
        }
        catch (error){
            console.log(error);
        }
    };
    const googleFailure = (error) => {
        console.log("Google Sign In was unsuccessful");
        console.log(error);
    };

    return (

        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar} style={{ backgroundColor: '#f6cee0',color:'#000' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5">{isSignup ? 'Sign Up' : 'Sign In'}</Typography>
                {msg ? <h4 style={{color:'#df4759',marginTop:'10px',marginBottom:'-10px'}}>{msg}</h4> : null}
                <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                          {
                              isSignup && (
                                  <>
                                      <Input name='firstName' label="First Name" handleChange={handleChange} required="required" half/>
                                      <Input name='lastName' label="Last Name" handleChange={handleChange} required="required" half/>
                                      </>
                              )}
                              <Input name="email" label="Email Address" handleChange={handleChange} type="email" required="required" />
                              <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} required="required" />
                          {isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" required="required" /> }
                      </Grid>
                    <Button type="submit" fullWidth variant="contained" colour="primary" className={classes.submit}>
                        {isSignup ? 'Sign Up' : 'Sign In'}
                    </Button>
                    {/*<GoogleLogin*/}
                    {/*    clientId='306136656000-i3f9g9npnlol12v1ikokvnd9heqppkb7.apps.googleusercontent.com'*/}
                    {/*    render={(renderProps) => (*/}
                    {/*        <Button*/}
                    {/*            className={classes.googleButton}*/}
                    {/*            color="primary"*/}
                    {/*            fullWidth*/}
                    {/*            onClick={renderProps.onClick}*/}
                    {/*            disabled={renderProps.disabled}*/}
                    {/*            startIcon={<Icon/>}*/}
                    {/*            variant="contained"*/}
                    {/*        >Continue with Google</Button>*/}
                    {/*    )}*/}
                    {/*    onSuccess={googleSuccess}*/}
                    {/*    onFailure={googleFailure}*/}
                    {/*    cookiePolicy="single_host_origin"*/}
                    {/*/>*/}
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            {/*<Button onClick={switchMode}>*/}
                            {/*    {isSignup ? 'Already have an account? Sign In' : 'Dont have an account? Sign Up'}*/}
                            {/*</Button>*/}
                            {isSignup ? <p>Already have an account? <Link style={{color: "#000"}} onClick={switchMode}>Sign In</Link></p> : <p>Don't have an account? <Link style={{color: "#000"}} onClick={switchMode}>Sign Up</Link></p>}
                        </Grid>
                    </Grid>
                </form>
            </Paper>

        </Container>
    )
}

export default Auth;