import React, { useState } from "react";
import {Button, Paper, Grid, Typography, Container} from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import useStyles from '../Auth/Styles';
import Input from '../Auth/Input'
import {ServerURL} from "../../constants/ServerURL";

const initialState = { name: '', email: '', listingTitle:'', location: '', details: '', price: '', description: '', rules: '', extras: ''}

const HostingForm = () => {

    const classes = useStyles();

    const history = useHistory();

    const [formData, setFormData] = useState(initialState);

    const [ valueA, setValueA ] = useState();

    const formData1 = new FormData();

    const handleSubmitForm = (e) =>{
        e.preventDefault();

        formData1.append('name', formData.name)
        formData1.append('email', formData.email)
        formData1.append('listingTitle', formData.listingTitle)
        formData1.append('location', formData.location)
        formData1.append('details', formData.details)
        formData1.append('price', formData.price)
        formData1.append('description', formData.description)
        formData1.append('rules', formData.rules)
        formData1.append('extras', formData.extras)

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(`${ServerURL}/hosting/hostingform`, formData1, config)
            .then((res) => setValueA(res.data.message))
            .catch(err => {
                console.error(err);
            });

        setTimeout(() => {history.push('/')}, 2000)

    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value})
    };

    const onChange = (e) => {

        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            formData1.append('photos', files[i])
        }
        // setFile(e.target.files);
    };

    return (

        <Container component="main" maxWidth="md">
            <Paper className={classes.paper} elevation={3}>
                <Typography variant="h5">Hosting Form</Typography>
                <form className={classes.form} onSubmit={handleSubmitForm} >

                    <Grid container spacing={4}>

                        <Grid item xs={12}>
                            <label>Your name:</label>
                            <Input name='name' placeholder="Name: (this will not be public)" type="text" handleChange={handleChange} required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Your email:</label>
                            <Input name="email" placeholder="Email Address: (this will not be public)" type="email" handleChange={handleChange} required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Listing title:</label>
                            <Input name="listingTitle" placeholder="eg. Gorgeous Waterside Eco-Barn" handleChange={handleChange} type='text' required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Location:</label>
                            <Input name="location" placeholder="eg. 1 Hamlet Cottage, Drew Rd, Chulmleigh, EX17 7ND: (this will not be public)" handleChange={handleChange} type='text' multiline={true} required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>How many guests/bedrooms/bathrooms:</label>
                            <Input name="details" placeholder="eg. 2 guests/ 1 bedroom/ 1 bathroom" handleChange={handleChange} type='text' required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Price per night:</label>
                            <Input name="price" placeholder="150" handleChange={handleChange} type='text' required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Description:</label>
                            <Input name="description" placeholder="Show yourself off here!" handleChange={handleChange} type='text' multiline={true} required={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <label>House Rules:</label>
                            <Input name="rules" placeholder="eg. No smoking/ No pets" handleChange={handleChange} type='text' required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Anything else:</label>
                            <Input name="extras" placeholder="Extras to display on the listing or for us to know!" handleChange={handleChange} type='text' multiline={true} required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <label>Photos:</label>
                            <p>
                                <small>Please attach photos to show off your stay! If you wish to add any more feel free to email us.</small>
                            </p>
                            <Input name="photos" handleChange={onChange} type="file" required={true} multiple={true} />
                        </Grid>

                    </Grid>
                    <Button type="submit" fullWidth variant="contained" colour="primary" className={classes.submit}>
                        Submit Form
                    </Button>
                </form>
                {valueA ? (<i className="fas fa-check-circle" style={{color:"#4ee44e"}}> {valueA}</i>) : null}
            </Paper>

        </Container>
    )
}

export default HostingForm;