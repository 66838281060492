import React from 'react';
import '../../App.css';
import Cards from '../Homepage-Components/Cards';
import HeroSection from '../Homepage-Components/HeroSection';
import SearchBox from '../Homepage-Components/SearchBox'
import BookHostConfidence from "../Homepage-Components/BookHostConfidence";

function Home() {
  return (

    <>

      <HeroSection />
      <SearchBox />
      <Cards />
      <BookHostConfidence />
    </>
  );
}

export default Home;
