import React from 'react';
import '../../../App.css';

import {Example} from "../../Homepage-Components/Date-Picker";
import BookingListings from "../../Booking-Components/BookingListings";

export default function TopPicks(){
    return(
        <>
            <div className={"search-title"}>
                <Example/>
            </div>

            <BookingListings/>
        </>
    )
}