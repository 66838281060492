const errorReducer = (state = { error: null }, action) => {
    switch (action.type){

        case "AUTH_FAIL":
            return { ...state, error: action?.data.message};

        case "CLEAR_ERROR":
            return { ...state, error: null};

        default:
            return state;
    }
}

export default errorReducer;