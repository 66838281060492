import React from 'react';
import { Link } from 'react-router-dom';
import {Button1} from "./Button";

function CardItemTxtTop(props) {
  return (

      <>
          <div className='cards__item'>
              <Link className='cards__item__link' to={props.path} >
                  <div className='pre--image' >
                      <figure className='cards__item__pic-wrap' data-category={props.label} style={{borderStyle: 'solid',borderWidth: '1px', borderRadius: '10px', borderColor: '#000'}}>
                          <img
                              className='cards__item__img'
                              alt='Travel'
                              src={props.src}
                          />
                      <div className='cards__item__inner__text' style={{color: props.txtcol}} >
                          <h5 className='cards__item__h5'>{props.text}</h5>
                          <p className='cards__item__p'>{props.text2}</p>
                      <div className='learn-more'>
                          <Button1 buttonStyle={props.btnside} buttonSize="null">Learn more</Button1>
                      </div>
                      </div>
                      </figure>
                  </div>
              </Link>
          </div>
      </>
  );
}

export default CardItemTxtTop;