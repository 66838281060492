import axios from 'axios';
import {ServerURL} from "../constants/ServerURL";

axios.defaults.withCredentials = true;

const API = axios.create({ baseURL: ServerURL});


export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const logout = () => API.post('/user/logout');

export const checkAuth = () => API.get('/user/checkauth');

