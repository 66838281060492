import React from 'react';
import IndividualListing from "../../Booking-Components/IndividualListing";
import { useParams } from 'react-router-dom'

export default function IndividualListingPage(){

    const {id} = useParams()

    return(
        <>
            <IndividualListing id = {id}/>
        </>
    )
}