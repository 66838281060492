import React from "react";
import Carousel1 from "./Carousel";

import {listings} from "../../constants/listings";

const BookingListings = () => {

    return (
        <>
            <div className='cards'>
                <div className='cards__container'>
                    <div className='cards__wrapper'>

                        <div className="cards_row">
                            <div className="cards_column">
                                {listings[0] && <Carousel1 listing={listings[0]}/>}
                            </div>
                            <div className="cards_column">
                                {listings[1] && <Carousel1 listing={listings[1]}/>}
                            </div>
                            <div className="cards_column">
                                {listings[2] && <Carousel1 listing={listings[2]}/>}
                            </div>
                            <div className="cards_column">
                                {listings[3] && <Carousel1 listing={listings[3]}/>}
                            </div>
                        </div>

                        <div className="cards_row">

                            <div className="cards_column">
                                {listings[4] && <Carousel1 listing={listings[4]}/>}
                            </div>
                            <div className="cards_column">
                                {listings[5] && <Carousel1 listing={listings[5]}/>}
                            </div>
                            <div className="cards_column">
                                {listings[6] && <Carousel1 listing={listings[6]}/>}
                            </div>
                            <div className="cards_column">
                                {listings[7] && <Carousel1 listing={listings[7]}/>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingListings;