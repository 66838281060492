import React from 'react';
import Select from 'react-select';

const options = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6+', label: '6+' },

]

// const groupStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     color:'green'
// };
//
// export const GuestsDropdown = () => (
//     <Select defaultValue={"Any"} options={options} styles={groupStyles}/>
// )

const customStyles = {

    option: (provided, state) => ({
        ...provided,
        // borderBottom: '1px dotted pink',
        color: state.isSelected ? 'white' : 'black',
        padding: 10,
    }),

    control: base => ({
        ...base,
        // none of react-select's styles are passed to <Control />
        position: 'relative',
        display: 'flex',
        width: 100,
        marginTop: 42,
        backgroundColor:'#fff',
        padding: 6.5,
        cursor: "pointer",
        border: 0,
        boxShadow: 'none',
        borderRadius: 0
    }),

    placeholder: base => ({
        ...base,
        color:"rgb(105,105,105)",
        fontWeight:1

    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition};
    }
}


export const GuestsDropdown = () => (
    <Select className={"select"}
        options={options}
        styles={customStyles}
        placeholder={"Any"}
            noOptionsMessage={() => 'No Results'}

    />
);
