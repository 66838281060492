// export function Button() {
//   return (
//     <Link to='sign-up'>
//       <button className='btn'>Sign Up</button>
//     </Link>
//   );
// }

import React from 'react';
import './Button.css';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test', 'sign-Up-btn--outline', 'search-btn--outline', 'left', 'right'];

const SIZES = ['btn--medium', 'btn--large', 'sign-Up-btn--medium', 'search-btn--medium', 'null'];

export const Button1 = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    // <Link to='/sign-up' className='btn-mobile'>
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    // </Link>
  );
};
