import React from 'react';
import '../../App.css';

import Dashboard from "../Account-components/Dashboard";

export default function Account(){

    return(
        <>
            <Dashboard/>
        </>
    )
}