import React from "react";
import {TextField, Grid, InputAdornment, IconButton} from "@material-ui/core";

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'


const Input = ({ name, handleChange, label, half, autoFocus, type, handleShowPassword, required, placeholder, multiple}) => (
    <Grid item xs={12} sm={half ? 6 : 12}>
        <TextField
            name={name}
            onChange={handleChange}
            variant="outlined"
            required={required}
            fullWidth
            label={label}
            placeholder={placeholder}
            autoFocus={autoFocus}
            type={type}
            inputProps={{multiple:multiple}}

            InputProps={name === 'password' ? {
                endAdornment : (
                    <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                        {type === 'password' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>

                ),
            } : null}

            InputProps={name === 'price' ? {

                startAdornment : (
                    <InputAdornment position="start">£</InputAdornment>
            ),
            } : null}


            />
    </Grid>
);

export default Input;