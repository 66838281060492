import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useHistory} from "react-router-dom";
import './Dashboard.css'

//
import {logout} from "../../actions/auth";
import {useDispatch} from "react-redux";
import {Button1} from "../Homepage-Components/Button";
import {ServerURL} from "../../constants/ServerURL";
//

const Dashboard = () => {

    const accountDetails = {name: '', recentStays: '', yourListings: ''}

    const [details, setDetails] = useState(accountDetails);

    useEffect(() => {
        axios.post(`${ServerURL}/user/accountDetails`).then((res)=> {setDetails({name: res.data.name,recentStays: res.data.recentStays, yourListings: res.data.yourListings})})
    },[])

    const dispatch = useDispatch();
    const history = useHistory();

    //
    const logOut = () =>{
        dispatch(logout(history))
    }
    //

    return(
        <>
            <div className='accountDashboard'>
                <div>
                    <h3>Hello {details.name},</h3>
                </div>

                <div className='pageDescription'>
                    <h3>Your recent stays:</h3>
                    <div className='pageDescription_txt'>
                        {details.recentStays.id ? <Link style={{color:'#000'}} to={`/stays/${details.recentStays.id}`}>{details.recentStays.title}</Link> : "No stays to display yet. Get travelling!" }
                    </div>

                </div>

                <div className='pageDescription'>
                    <h3>Your listings:</h3>
                    <div className='pageDescription_txt'>
                        {details.yourListings.id ? <Link style={{color:'#000'}} to={`/stays/${details.yourListings.id}`}>{details.yourListings.title}</Link> : "No listings yet. Why not become a host?"}
                    </div>
                </div>

                <div className='btn-mobile logOutBtn'>
                    <Button1 buttonStyle='sign-Up-btn--outline' buttonSize='sign-Up-btn--medium' onClick={()=>{logOut()}} >Log Out</Button1>
                </div>

            </div>
        </>
    )
}

export default Dashboard;