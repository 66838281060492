import React from 'react';
import './ContactCards.css';
import '../Homepage-Components/BookHostConfidence.css'
import '../Homepage-Components/Cards.css'

export function ContactCardItem(props) {
    return (
        <>
            <div className='contact_cards__item' >
            <a className='contact_cards__item__link' href={props.href} >
                <div className='contact_pre--image' >
                    <figure className='contact_cards__item__pic-wrap' data-category={props.label} style={{borderStyle: 'solid',borderWidth: '1px', borderRadius: '10px', borderColor: '#000'}}>
                        <img
                            className='contact_cards__item__img'
                            alt='Travel'
                            src={props.src}
                        />
                        <div className='contact_cards__item__text'>
                            <h5 className='contact_cards__item__h5'>{props.text}</h5>
                        </div>
                    </figure>
                </div>
            </a>
        </div>
    </>
    );
}


export function ContactCards() {
    return (
        <>
            <div className='cards'>
                <h1 className='ContactUs'>Welcome to CloudJoy Customer Service</h1>
                <br/>
                <h3>Whatever your query is please feel free get in touch with a representative.</h3>
                <br/>
                <h2>Please choose a contact method that works best for you by clicking one of the options below.</h2>

                <div className='cards'>
                    <div className='cards__container'>
                        <div className="contact_cards_row ">
                            <div className="contact_cards_column">
                                <ContactCardItem
                                    src='images/ContactIcons/email.png'
                                    text='Email'
                                    href='mailto:cloudjoy@gmail.com'
                                    label='Email'
                                />
                            </div>
                            <div className="contact_cards_column">
                                <ContactCardItem
                                    src='images/ContactIcons/chat.png'
                                    text='Text'
                                    href='sms://+447488281893'
                                    label='Text'
                                />
                            </div>
                        </div>
                        <div className="contact_cards_row ">
                            <div className="contact_cards_column">
                                <ContactCardItem
                                    src='images/ContactIcons/Call.png'
                                    text='Phone'
                                    href='tel:+447488281893'
                                    label='Phone'
                                />
                            </div>
                            <div className="contact_cards_column">
                                <ContactCardItem
                                    src='images/ContactIcons/submit-form.png'
                                    text='Submit Form'
                                    label='Submit Form'
                                    href='/submit-a-form'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}