import React, { useState, useEffect } from 'react';
// import {useDispatch, useSelector} from "react-redux";
// import {logout} from "../../actions/auth";
import { Button1 } from './Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './Navbar.css';
import AuthGuard from "../HOCs/authGuard";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  // auth
  // const dispatch = useDispatch();
  // const history = useHistory();
  const proceed = AuthGuard();
  const location = useLocation();

  const [user, setUser] = useState(true);

  // const logOut = () =>{
  //   dispatch(logout(history))
  //   setUser(null)
  // }

  useEffect(() => {
    proceed ? setUser(true) : setUser(false)
    }, [location, proceed])

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img className={'navbar-logo-pic'} src={'/images/logo.png'} alt={'Cloudjoy logo'}/>
            CloudJoy
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/top-picks' className='nav-links' onClick={closeMobileMenu}>
                Top Picks
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/become-a-host'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Become a Host
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>

            {/*<li className='nav-item'>*/}
            {/*  <Link*/}
            {/*      className='nav-links'*/}
            {/*      onClick={()=>{ closeMobileMenu(); logOut() }}*/}
            {/*  >*/}
            {/*    Log Out*/}
            {/*  </Link>*/}
            {/*</li>*/}

            <li>
              {user ? (
                    <Link
                        to='/account'
                        className='nav-links-mobile'
                        onClick={closeMobileMenu}
                    >
                      Account
                    </Link>
              ) : (
                    <Link
                        to='/auth'
                        className='nav-links-mobile'
                        onClick={closeMobileMenu}
                    >
                      Sign In
                    </Link>
              )}
            </li>
          </ul>
          {user ? (
              <>
                {button && <Link to='/account' className='btn-mobile '> <Button1 buttonStyle='sign-Up-btn--outline' buttonSize='sign-Up-btn--medium'>Account</Button1></Link>}
                {/*{button && <Button1 buttonStyle='sign-Up-btn--outline' buttonSize='sign-Up-btn--medium'>Account</Button1>}*/}
              </>
          ) : (
              <>
                {button && <Link to='/auth' className='btn-mobile '> <Button1 buttonStyle='sign-Up-btn--outline' buttonSize='sign-Up-btn--medium'>Sign In</Button1></Link>}
              </>
          )}
        </div>
      </nav>
    </>
  );
}
export default Navbar;
