import { AUTH, LOGOUT } from '../constants/actionTypes'

const authReducer = (state = { authData: null, isAuthenticated: false }, action) => {
    switch (action.type){
        case AUTH:
            document.cookie = `session_id = ${action?.data.result._id}`
            return { ...state, authData: action?.data, isAuthenticated: true};
        case "CHECK_AUTH":
            return { ...state, authData: action?.data};
        case LOGOUT:
            localStorage.clear();
            document.cookie = 'session_id = null'
            return { ...state, authData: null, isAuthenticated: false };
        default:
            return state;
    }
}

export default authReducer;