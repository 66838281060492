import CardItemTxtTop from "./CardItemTxtTop";
import React from "react";
import './Cards.css';
import './BookHostConfidence.css'
import './Cards.css'

function BookHostConfidence() {
    return (
        <div className='cards'>
            <div className='cards__container'>
                <div className="cards_row">
                    <div className="txt_cards_column">

                        <CardItemTxtTop
                            src='images/fff.png'
                            text={"Book with confidence"}
                            text2={"Enjoy excellent stays with a company who cares"}
                            label='Adrenaline'
                            path='/booking/learn-more'
                            txtcol='#000'
                            btnside="left"
                        />
                    </div>

                    <div className="txt_cards_column">
                        <CardItemTxtTop
                            src='images/000.png'
                            text='Host with confidence'
                            text2="Generate income by listing your property"
                            label='Adrenaline'
                            path='/hosting/learn-more'
                            txtcol='#fff'
                            btnside="right"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookHostConfidence;