import React, {useState} from 'react';
import {listings} from "../../constants/listings";
import Carousel from 'react-material-ui-carousel'

import './IndividualListing.css'

export default function IndividualListing(props) {

    const [show,Setshow] = useState(false)

    const enter = () => {
        Setshow(true)
    }

    const leave = () => {
        Setshow(false)
    }

    const id = props.id;
    let x = null;


    for (let i in listings){
        if (listings[i].id === id ){
            x = listings[i]
        }
    }

    return (
        <>
            <div className='pageContainer'>

                <div className='pageTitle'>
                    <h2>{x?.title}</h2>
                </div>

                <div className='pageLocationPrice'>
                    <h3>{x?.location}</h3>
                </div>

                <a href='tel:+447488281893' >
                    <div className='bookNowdiv'>
                        <div className='bookNow' >
                            <i className="fas fa-phone-alt" > Book now - 07488281893</i>
                        </div>
                    </div>
                </a>

                <div onMouseEnter={enter} onMouseLeave={leave}>
                <Carousel className={'pageCarousel'}
                          indicators={false}
                          animation={'slide'}
                          fullHeightHover={false}
                          cycleNavigation={true}
                          autoPlay={false}
                          navButtonsAlwaysVisible={show}
                          navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                              style: {
                                  backgroundColor: '#fff',
                                  color:'#000'
                              }
                          }}
                          navButtonsWrapperProps={{
                              style: {

                              }
                          }}
                >

                    <Item item = {`/${x?.src1}`}/>
                    <Item item = {`/${x?.src2}`}/>
                    <Item item = {`/${x?.src3}`}/>
                    <Item item = {`/${x?.src4}`}/>
                    <Item item = {`/${x?.src5}`}/>
                    <Item item = {`/${x?.src6}`}/>
                    <Item item = {`/${x?.src7}`}/>
                    <Item item = {`/${x?.src8}`}/>

                </Carousel>

                </div>

                <div className='pageDetails' >
                    <h3>Details:</h3>
                    <ul className='pageDetails_list'>
                        {x?.price && <li>- £{x?.price} per night</li>}
                        {x?.details[0] && <li>{x?.details[0]}</li>}
                        {x?.details[1] && <li>{x?.details[1]}</li>}
                        {x?.details[2] && <li>{x?.details[2]}</li>}
                        {x?.details[3] && <li>{x?.details[3]}</li>}
                        {x?.details[4] && <li>{x?.details[4]}</li>}
                        {x?.details[5] && <li>{x?.details[5]}</li>}
                        {x?.details[6] && <li>{x?.details[6]}</li>}
                        {x?.details[7] && <li>{x?.details[7]}</li>}
                        {x?.details[8] && <li>{x?.details[8]}</li>}
                        {x?.details[9] && <li>{x?.details[9]}</li>}
                        {x?.details[10] && <li>{x?.details[10]}</li>}
                        {x?.details[11] && <li>{x?.details[11]}</li>}
                        {x?.details[12] && <li>{x?.details[12]}</li>}
                        {x?.details[13] && <li>{x?.details[13]}</li>}
                    </ul>

                </div>

                {x.extraServices && (<div className='pageDetails' >
                    <h3>Services available:</h3>
                    <ul className='pageDetails_list'>
                        {x?.extraServices[0] && <li>{x?.extraServices[0]}</li>}
                        {x?.extraServices[1] && <li>{x?.extraServices[1]}</li>}
                        {x?.extraServices[2] && <li>{x?.extraServices[2]}</li>}
                        {x?.extraServices[3] && <li>{x?.extraServices[3]}</li>}
                    </ul>

                </div>)}

                <div className='pageDescription'>
                    <h3>Description:</h3>
                    <p className='pageDescription_txt' >{x?.description}</p>
                </div>

                <div className='pageDescription'>
                    <h3>Extras:</h3>
                    <p className='pageDescription_txt' >{x?.extras}</p>
                </div>

                <div className='pagePhotos'>
                    <h3>Photos:</h3>
                    <div className='rows'>
                        <div className="row">
                            <div className="column">
                                {x.src1 && <img src={`/${x?.src1}`} alt="Snow" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src2 && <img src={`/${x?.src2}`} alt="Forest" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src3 && <img src={`/${x?.src3}`} alt="Mountains" className='column_Imgs' />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                {x.src4 && <img src={`/${x?.src4}`} alt="Snow" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src5 && <img src={`/${x?.src5}`} alt="Forest" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src6 && <img src={`/${x?.src6}`} alt="Mountains" className='column_Imgs' />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                {x.src7 && <img src={`/${x?.src7}`} alt="Snow" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src8 && <img src={`/${x?.src8}`} alt="Forest" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src9 && <img src={`/${x?.src9}`} alt="Mountains" className='column_Imgs' />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                {x.src10 && <img src={`/${x?.src10}`} alt="Snow" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src11 && <img src={`/${x?.src11}`} alt="Forest" className='column_Imgs' />}
                            </div>
                            <div className="column">
                                {x.src12 && <img src={`/${x?.src12}`} alt="Mountains" className='column_Imgs' />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                {x.src13 && <img src={`/${x?.src13}`} alt="Snow" className='column_Imgs' />}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

function Item(props)
{
    return (
        <>
                <div className='cards__item__pic-wrap' >
                    <img className='cards__item__img' src={props.item} />
            </div>
        </>

    )
}