import React from 'react';
import '../../App.css';
import { Button1 } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <h1>Explore the Clouds</h1>
      {/*<p>CloudJoy</p>*/}
      <div className='hero-btns'>
        <Button1
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
            Book Now
        </Button1>
        <Button1
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Inspiration Reel<i className='far fa-play-circle'/>
        </Button1>
      </div>
    </div>
  );
}

export default HeroSection;
