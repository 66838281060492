import {useEffect, useState} from 'react';
import axios from "axios";
import {ServerURL} from "../../constants/ServerURL";

const AuthGuard = () => {

    const [proceed, setProceed] = useState(false)

    useEffect(()=>{
        axios.post(`${ServerURL}/user/checkauth`).then((res)=> {setProceed(true)}).catch((err)=>{setProceed(false)})

    })
    return proceed;

}

export default AuthGuard;
