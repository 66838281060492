import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel'

function Carousel1(props) {

    const [show,Setshow] = useState(false)

    const enter = () => {
        Setshow(true)
    }

    const leave = () => {
        Setshow(false)
    }

    return (
        <>
            <li className='cards__item' style={{marginBottom:'3vw'}}>

                <div className='cards__item__link' onMouseEnter={enter} onMouseLeave={leave}>
                    {/*<div className={'pre--image'}>*/}
                    {/*<figure className='cards__item__pic-wrap' >*/}

                    {/*<img*/}
                    {/*  className='cards__item__img'*/}
                    {/*  alt='Travel'*/}
                    {/*  src={props.src}*/}
                    {/*/>*/}
                    <Carousel className={'pre--image'}
                              indicators={false}
                              animation={'slide'}
                              fullHeightHover={false}
                              cycleNavigation={false}
                              autoPlay={false}
                              navButtonsAlwaysVisible={show}
                              navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                  style: {
                                      backgroundColor: '#fff',
                                      color:'#000'
                                  }
                              }}
                              navButtonsWrapperProps={{
                                  style: {

                                  }
                              }}
                    >
                        <Item item = {props.listing.src1} path={`/stays/${props.listing.id}`}/>
                        <Item item = {props.listing.src2} path={`/stays/${props.listing.id}`}/>
                        <Item item = {props.listing.src3} path={`/stays/${props.listing.id}`}/>
                        <Item item = {props.listing.src4} path={`/stays/${props.listing.id}`}/>

                    </Carousel>

                    {/*</figure>*/}
                    {/*  </div>*/}
                    <Link className='cards__item__link' to={`/stays/${props.listing.id}`}>

                        {/*<div className='cards__item__info'>*/}
                        {/*    /!*<h5 className='cards__item__text'>{props.title}</h5>*!/*/}
                        {/*    /!*<p className='cards__item__text'>{props.location}</p>*!/*/}
                        {/*</div>*/}

                        <div className='cards__item__info' style={{display:'flex',justifyContent:'space-between'}}>

                            <p className='cards__item__text'>{props.listing.location}</p>
                            <p className='cards__item__text'>{`£${props.listing.price} / night`}</p>

                        </div>

                    </Link>
                </div>
            </li>
        </>
    );
}

function Item(props)
{
    return (

        <Link className='cards__item__link' to={props.path}>
            <div className={'pre--image'}>
                <figure className='cards__item__pic-wrap' >

                    <img className='cards__item__img' src={props.item} />

                </figure>
            </div>
        </Link>

    )
}

export default Carousel1;