import {AUTH, AUTH_FAIL, LOGOUT} from '../constants/actionTypes';
import * as API from '../api/index.js';

export const signin = (formData, history) => async (dispatch) => {
    try {
        const { data }  = await API.signIn(formData);
        dispatch({ type: AUTH, data });

        history.push('/')
    }
    catch (error){
        const data = error.response.data;
        dispatch({ type: AUTH_FAIL, data })
    }
}

export const signup = (formData, history) => async (dispatch) => {
    try {
        const { data } = await API.signUp(formData);

        dispatch({ type: AUTH, data });

        history.push('/')
    }
    catch (error){
        const data = error.response.data;
        dispatch({ type: AUTH_FAIL, data })
    }
}

export const logout = (history) => async (dispatch) => {
    try {
        const { data }  = await API.logout();
        dispatch({ type: LOGOUT, data });

        history.push('/')
    }
    catch (error){
        console.log(error)
    }
}

export const checkAuth = () => async (dispatch) => {
    try {
        const { data }  = await API.checkAuth();
        dispatch({ type: 'CHECK_AUTH', data });
    }
    catch (error){
        console.log(error)
    }
}
