import React from 'react';
import './SearchBox.css';
import {Example} from "./Date-Picker";

function SearchBox() {


    return (
        <div className={"search-title"}>
            <h1>Start your search</h1>
            <Example/>
        </div>
    );
}

export default SearchBox;
