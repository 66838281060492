import React, { useState } from "react";
import {Button, Paper, Grid, Typography, Container} from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import useStyles from '../Auth/Styles';
import Input from '../Auth/Input'
import {ServerURL} from "../../constants/ServerURL";

const initialState = { name: '', email: '', enquiry:''}

const SubmitFormLayout = () => {
    const classes = useStyles();

    const history = useHistory();

    const [formData, setFormData] = useState(initialState);

    const [ valueA, setValueA ] = useState();

    const [ file, setFile ] = useState('')



    const handleSubmitForm = (e) =>{
        e.preventDefault();

        const formData1 = new FormData();

        formData1.append('name', formData.name)
        formData1.append('email', formData.email)
        formData1.append('enquiry', formData.enquiry)
        formData1.append("uploaded_file", file);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }


        axios.post(`${ServerURL}/contact/submitform`, formData1, config)
            .then((res) => setValueA(res.data.message))
            .catch(err => {
                console.error(err);
            });

        setTimeout(() => {history.push('/')}, 2000)

    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value})
    };

    const onChange = (e) => {
        setFile(e.target.files[0]);
    };

    return (

        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Typography variant="h5">Submit Form</Typography>
                <form className={classes.form} onSubmit={handleSubmitForm} >
                    <Grid container spacing={2}>

                        <Input name='name' label="Name" type="text" handleChange={handleChange} required={true} />
                        <Input name="email" label="Email Address" type="email" handleChange={handleChange} required={true} />
                        <Input name="enquiry" label="Enquiry" handleChange={handleChange} type='text' required={true} />
                        <Input name="uploaded_file" handleChange={onChange} type="file" required={false} multiple={false} />

                    </Grid>
                    <Button type="submit" fullWidth variant="contained" colour="primary" className={classes.submit}>
                        Submit Form
                    </Button>
                </form>
                {valueA ? (<i className="fas fa-check-circle" style={{color:"#4ee44e"}}> {valueA}</i>) : null}
            </Paper>

        </Container>
    )
}

export default SubmitFormLayout;
